import $, { type StylixProps, useKeyframes } from '@stylix/core';
import { useNewInvestigationsContext } from 'Components/App/NewInvestigationsContext';
import { Checkbox } from 'Components/App/modules/InvestigationNew/ui-shared/Checkbox';
import { Tooltip as NewTooltip } from 'Components/App/modules/InvestigationNew/ui-shared/Popover';
import { useTheme } from 'Components/App/modules/InvestigationNew/ui-shared/theme';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function newInvestigationsEnabled() {
  return !localStorage.getItem('disable-new-investigations');
}

export function toggleNewInvestigations(enable?: boolean) {
  enable ??= !newInvestigationsEnabled();
  if (enable) {
    localStorage.removeItem('disable-new-investigations');
    if (window.location.href.includes('/investigation/')) {
      window.location.href = window.location.href.replace('/investigation/', '/investigation-new/');
    }
  } else {
    localStorage.setItem('disable-new-investigations', '1');
    if (window.location.href.includes('/investigation-new/')) {
      window.location.href = window.location.href.replace('/investigation-new/', '/investigation/');
    }
  }
}

export type GlowToggleProps = StylixProps;

export function NewInvestigationsToggle(props: GlowToggleProps) {
  const { ...styles } = props;

  const { themeColors } = useTheme();

  const bgAnimation = useKeyframes({
    '0%': {
      backgroundPositionX: '0%',
    },
    '100%': {
      backgroundPositionX: '-200%',
    },
  });

  const opacityAnimation = useKeyframes({
    '0%': {
      opacity: 0,
    },
    '20%': {
      opacity: 0,
    },
    '25%': {
      opacity: 1,
    },
    '35%': {
      opacity: 1,
    },
    '40%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  });

  const newInvestigationsContext = useNewInvestigationsContext();

  const flags = useFlags();
  if (!flags.newInvestigations) return null;

  return (
    <NewTooltip
      content={
        <$.div font-size={16} p={6}>
          Enable the switch to preview the new investigations experience
        </$.div>
      }
    >
      <$.div padding="8px 16px 8px 10px" flex-center>
        <$.div
          padding={2}
          border-radius={50}
          block
          relative
          z-index={0}
          $css={{
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 10%, #81c400 20%, #fec837 30%, #fd1892 40%, #b827fc 50%, #2c90fc 60%, #81c400 70%, #fec837 80%, #fd1892 90%, #b827fc 100%)',
              backgroundSize: '200% 200%',
              'abs-fill': true,
              animation: newInvestigationsContext.enabled
                ? 'none'
                : `${bgAnimation} 3s linear infinite, ${opacityAnimation} 20s linear infinite`,
              zIndex: 0,
              borderRadius: 50,
              transition: 'opacity 0.3s linear',
              opacity: newInvestigationsContext.enabled ? 0 : 1,
            },
            '.Checkbox-toggle:focus': {
              outline: 'none',
            },
          }}
          {...styles}
        >
          <$.div relative z-index={1} background={themeColors.neutral[100]} border-radius={50}>
            <Checkbox
              flexbox
              large
              checked={newInvestigationsContext.enabled}
              variant="toggle"
              onChange={() => {
                newInvestigationsContext.setEnabled(!newInvestigationsContext.enabled);
                toggleNewInvestigations(!newInvestigationsContext.enabled);
              }}
              font-weight={500}
              font-size={18}
            />
          </$.div>
        </$.div>
        <$.div
          ml={10}
          font-size={18}
          font-weight={500}
          color={
            newInvestigationsContext.enabled ? themeColors.sky[700] : themeColors.contrast[100]
          }
          transition="color 200ms linear"
        >
          NEW INVESTIGATIONS
        </$.div>
      </$.div>
    </NewTooltip>
  );
}
