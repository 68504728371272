import { withNewInvestigationsContext } from 'Components/App/NewInvestigationsContext';
import { getDebugValues } from 'Components/useDebugValues';
import {
  AcceptLatestTermsOfService,
  Authenticate,
  GetCurrentUserSession,
  GetUserSecret,
  GetUserSettings,
  MarkNotificationRead,
  SearchNotifications,
  UpdateCurrentUserSession,
  UpdateUser,
  UpdateUserSettings,
  UserSettings,
} from 'Root/dtos.ts';
import client, { setToken } from 'Services/client';
import LocalStorage from 'Services/customLocalStorage';
import serverEventsClientInit, { seClient } from 'Services/serverEventsClient';
import { tracker } from 'Services/tracker';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

export const UserContext = React.createContext();

const initialState = {
  roles: [],
  agencyId: null,
  permissions: [],
  accountStatus: null,
  email: '',
  firstName: '',
  lastName: '',
  hasPIN: true,
  hasPINSaved: false,
  userId: -1,
  loginError: null,
  notifications: [],
  unreadNotificationsCount: 0,
  stateUserName: '',
  statePasswordExpirationDate: '',
  fullUserLoaded: false,
  userHasAuthenticator: false,
  isAuthenticated: false,
  settings: new UserSettings({
    theme: 'light',
    saveDataTableFilters: true,
    defaultDashboardTab: 'Investigations',
    defaultMyRecordsTab: '/records/alert',
    defaultSystemAdminTab: '/system-management/agencies',
    defaultAgencyAdminTab: '/agency-management/agency-settings',
  }),
};

class _UserProvider extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  constructor() {
    super();
    UserProvider.instance = this;
  }

  state = {
    user: (() => {
      try {
        const user = JSON.parse(localStorage.getItem('user')) || {};
        user.settings = { ...initialState.settings, ...user.settings };
        return user;
      } catch (e) {
        console.warn('Error loading user from local storage.', e);
        return {};
      }
    })(),
  };

  /**
   * This is assigned when the component mounts and is available globally via Shell.instance
   * @type UserProvider
   */
  static instance = null;

  saveUser = (user) =>
    new Promise((resolve) =>
      this.setState(
        (state) => ({
          user: {
            ...state.user,
            ...user,
          },
        }),
        () => {
          localStorage.setItem('user', JSON.stringify(this.state.user));
          resolve();
        },
      ),
    );

  acceptTOS = async () => {
    await Promise.all([
      client.post(new AcceptLatestTermsOfService()),
      this.saveUser({
        requiresLatestTermsOfServiceAcceptance: false,
      }),
    ]);
  };

  getNotifications = async () => {
    const search = new SearchNotifications();
    search.take = 10;
    search.read = false;
    search.orderByDesc = 'createdDate';
    search.include = 'COUNT(*)';
    const data = await client.get(search);

    this.saveUser({
      notifications: data.results,
      unreadNotificationsCount: data.total,
    });
  };

  markNotificationRead = async (id) => {
    const markRead = new MarkNotificationRead();
    markRead.id = id;
    await client.put(markRead);

    const notifications = cloneDeep(this.state.user.notifications);
    let { unreadNotificationsCount } = this.state.user;
    const notification = notifications.find((x) => x.id === id);
    // If the notification exists and is not read, mark it as read and decrease the unread count
    if (notification && !notification.read) {
      notification.read = true;
      unreadNotificationsCount -= 1;
    }

    this.saveUser({
      notifications,
      unreadNotificationsCount,
    });
  };

  notificationReceived = (msg) => {
    this.saveUser({
      // we only want 10 in our top bar
      notifications: [msg, ...this.state.user.notifications].slice(0, 10),
      unreadNotificationsCount: this.state.user.unreadNotificationsCount + 1,
    });
  };

  updateUser = async (user) => {
    const update = new UpdateUser();
    Object.assign(update, user);
    await client.put(update);

    if (user.id === this.state.user.userId) {
      this.saveUser({
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  };

  loadUserSettings = async () => {
    const getSettingsRequest = new GetUserSettings();
    const settings = await client.get(getSettingsRequest);
    await this.saveUserSettings(settings);
  };

  updateUserSettings = async (settings = this.state.user.settings) => {
    const updateSettings = new UpdateUserSettings({ settings });
    await client.put(updateSettings);
  };

  saveUserSettings = async (settings) => {
    await this.saveUser({
      settings: {
        ...this.state.user.settings,
        ...settings,
      },
    });
    await this.updateUserSettings(this.state.user.settings);
  };

  logIn = async (email, password, moreParams) => {
    const request = new Authenticate();
    request.userName = email;
    request.password = password;
    Object.assign(request, moreParams);
    try {
      await client.post(request);

      await this.getCurrentSession();

      serverEventsClientInit();

      localStorage.removeItem('sessionLocked');

      this.props.newInvestigationsContext.setEnabled(true);

      tracker?.setUserID(email);
    } catch (error) {
      await this.saveUser({
        loginError: error,
      });
      throw error;
    }
  };

  getCurrentSession = async () => {
    const userSession = await client.get(new GetCurrentUserSession());
    const session = {
      ...userSession,
      exp: new Date(
        getDebugValues()?.session?.expiration
          ? Date.now() + getDebugValues()?.session?.expiration
          : userSession.expiration * 1000,
      ),
      /**
       * On successfull login save the `secretKey` to validate the session.
       * And whenever user get updated(on PermissionsUpdate) and new token get created
       * Check if we get `secretKey` else assign existing `secretKey`.
       * As on new session(user login from different machine) it will validate the secretKey,
       * So there is no harm to assign existing `secretKey`.
       */
      secretKey: userSession.secretKey || JSON.parse(localStorage.getItem('user'))?.secretKey,
      isAuthenticated: true,
      daysRemainingForPasswordExpiry: userSession.daysRemainingForPasswordExpiry,
    };
    await this.saveUser(session);
  };

  /**
   * This primarily gets called by serverEventsClient.js in the event that an Admin or Agency
   * Admin changes a users settings / permissions, he triggers a SSE "UserUpdatedMessage"
   *
   * Once the event is received from SSE, we call our endpoint to set a ss-tok, and return
   * the new bearer/refresh token.
   * @returns {Promise<void>}
   */
  refreshSession = async () => {
    const userSession = await client.post(new UpdateCurrentUserSession());
    const session = {
      ...userSession,
      exp: new Date(
        getDebugValues()?.session?.expiration
          ? Date.now() + getDebugValues()?.session?.expiration
          : userSession.expiration * 1000,
      ),
      /**
       * On successfull login save the `secretKey` to validate the session.
       * And whenever user get updated(on PermissionsUpdate) and new token get created
       * Check if we get `secretKey` else assign existing `secretKey`.
       * As on new session(user login from different machine) it will validate the secretKey,
       * So there is no harm to assign existing `secretKey`.
       */
      secretKey: userSession.secretKey || JSON.parse(localStorage.getItem('user'))?.secretKey,
      isAuthenticated: true,
    };
    await this.saveUser(session);
  };

  logOff = async (message, mobileOrAutoLogout = false) => {
    const request = new Authenticate();
    Object.assign(request, {
      provider: 'logout',
      meta: {
        mobileOrAutoLogout,
      },
    });
    await client.post(request);
    this.clearAnnouncementInterval();
    const stateHostCheckInterval = localStorage.getItem('checkStateHostInterval');

    if (stateHostCheckInterval) {
      clearInterval(stateHostCheckInterval);
    }

    localStorage.removeItem('checkStateHostInterval');

    setToken(null, null);
    localStorage.removeItem('form');
    await seClient?.stop();

    this.props.history.push('/login', { message });

    await this.saveUser(cloneDeep(initialState));

    window.dispatchEvent(new Event('logout'));
  };

  clearAnnouncementInterval = () => {
    const { user } = this.state;
    if (!user) return;
    const announcementCheckInterval = LocalStorage.getObj(`AnnouncementStatus-${user.userId}`);
    if (announcementCheckInterval) {
      clearInterval(announcementCheckInterval);
    }
  };

  validateSecretKey = async (userId) => {
    const request = new GetUserSecret({
      userId,
    });

    const { secretKey } = await client.get(request);
    const user = JSON.parse(localStorage.getItem('user')) || {};
    if (secretKey && user?.secretKey) {
      return user.secretKey === secretKey;
    }
    return false;
  };

  /*
   *This will get removed once we test the
   * Single session functionality on the server.
   * If it works with serverEvents.
   * We don't want this method.
   */

  logOffUser = async (userId) => {
    const valid = await this.validateSecretKey(userId);
    if (!valid) {
      this.logOff('Session expired');
    }
  };

  logOffUserOnInvalidSecretKey = async (secretKey) => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    if (user?.secretKey !== secretKey) {
      await this.logOff('Session expired', true);
    }
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state.user,
          acceptTOS: this.acceptTOS,
          getNotifications: this.getNotifications,
          markNotificationRead: this.markNotificationRead,
          notificationReceived: this.notificationReceived,
          updateUser: this.updateUser,
          saveUser: this.saveUser,
          updateUserSettings: this.updateUserSettings,
          saveUserSettings: this.saveUserSettings,
          loadUserSettings: this.loadUserSettings,
          logIn: this.logIn,
          logOff: this.logOff,
          refreshSession: this.refreshSession,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const UserProvider = compose(withRouter, withNewInvestigationsContext())(_UserProvider);
export default UserProvider;
